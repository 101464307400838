<script>
import AppBanner from "@/components/shared/AppBanner";
import Button from "../components/reusable/Button.vue";
import ServicesGrid from "../components/services/ServicesGrid.vue";
import ConditionsWeTreatGrid from "../components/conditionswetreat/ConditionsWeTreatGrid.vue";
import ContactUs from "../components/contactus/ContactUs.vue";
import HappyPatients from "../components/happypatients/HappyPatients.vue";
import Testimonials from "../components/testimonials/Testimonials.vue";
import MeetTheDoctor from "../components/meetthedoctor/MeetTheDoctor.vue";
import LaserSuccessStories from "../components/lasersuccessstories/LaserSuccessStories.vue";
import EducationalSeminars from "../components/educationalseminars/EducationalSeminars.vue";

export default {
  name: "Home",
  components: {
    AppBanner,
    Button,
    ConditionsWeTreatGrid,
    ContactUs,
    HappyPatients,
    MeetTheDoctor,
    ServicesGrid,
    Testimonials,
    LaserSuccessStories,
    EducationalSeminars,
  },
};
</script>

<template>
  <div class="container mx-auto">
    <!-- Banner -->
    <AppBanner class="mb-5 sm:mb-8" />
    
    <!-- Testimonials-->
    <Testimonials />

    <!-- Laser Success Stories-->
    <LaserSuccessStories />

    <!--Meet The Doctor-->
    <MeetTheDoctor />

    <!--Educational Seminars-->
    <EducationalSeminars />
    
    <!-- Services -->
    <ServicesGrid />

    <!-- Load more services button -->
    <div class="mt-10 sm:mt-20 flex justify-center">
      <router-link
        to="/services"
        class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl dark:bg-indigo-500 bg-dark-blue-light hover:bg-dark-blue-light dark:hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
        aria-label="More Services"
      >
        <Button title="More Services" />
      </router-link>
    </div>

    <!-- Conditions We Treat -->
    <ConditionsWeTreatGrid />

    <!-- Load more Conditions We Treat button -->
    <div class="mt-10 sm:mt-20 flex justify-center">
      <router-link
        to="/conditionswetreat"
        class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl dark:bg-indigo-500 bg-dark-blue-light hover:bg-dark-blue-light dark:hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
        aria-label="More Conditions We Treat"
      >
        <Button title="More Conditions We Treat" />
      </router-link>
    </div>

    <!-- Happy Patients-->
    <HappyPatients />

    <!-- Contact Us -->
    <ContactUs />
  </div>
</template>
