<script>
export default {
  props: ["contactUs"],
};
</script>

<template>
  <div class="mt-10 sm:mt-10">
    <h3
      class="font-general-regular text-gray-400
					mb-6 text-justify
				"
    >
      {{ contactUs.contactUsDescription }}
    </h3>
  </div>
  <div class="block sm:flex gap-0 sm:gap-10 mt-14">
    <div class="w-full sm:w-1/2 text-left">
      <section
        class="rounded-lg shadow-lg hover:shadow-xl cursor-pointer max-w-screen-lg mx-auto pb-4 mb-6"
        v-for="address in contactUs.address"
        :key="address.id"
      >
        <img
          :src="address.addressimg"
          class="mx-auto w-8 h-8 text-ternary-dark dark:text-ternary-light content-center mb-2"
        />
        <p
          class="text-2xl text-gray-800 font-general-semibold mb-3 dark:text-primary-light text-center"
        >
          {{ address.title }}
        </p>
        <ul class="leading-loose">
          <li class="font-general-regular text-gray-400 text-center">
            <span>{{ address.address1 }}</span>
          </li>
          <li class="font-general-regular text-gray-400 text-center">
            <span>{{ address.address2 }}</span>
          </li>
        </ul>
      </section>

      <section
        class="rounded-lg shadow-lg hover:shadow-xl cursor-pointer max-w-screen-lg mx-auto pb-4 mb-6"
        v-for="whatsapp in contactUs.whatsapp"
        :key="whatsapp.id"
      >
        <img
          :src="whatsapp.whatsappimg"
          class="mx-auto w-8 h-8 text-ternary-dark dark:text-ternary-light content-center mb-2"
        />
        <p
          class="text-2xl text-gray-800 font-general-semibold mb-3 dark:text-primary-light text-center"
        >
          {{ whatsapp.title }}
        </p>
        <ul class="leading-loose">
          <li class="font-general-regular text-gray-400 text-center">
            <a :href="whatsapp.link" target="_blank" class="underline">{{
              whatsapp.whatsappNumber
            }}</a>
          </li>
        </ul>
      </section>

      <section
        class="rounded-lg shadow-lg hover:shadow-xl cursor-pointer max-w-screen-lg mx-auto pb-4"
        v-for="email in contactUs.email"
        :key="email.id"
      >
        <img
          :src="email.emailimg"
          class="mx-auto w-8 h-8 text-ternary-dark dark:text-ternary-light content-center mb-2"
        />
        <p
          class="text-2xl text-gray-800 font-general-semibold mb-3 dark:text-primary-light text-center"
        >
          {{ email.title }}
        </p>
        <ul class="leading-loose">
          <li class="font-general-regular text-gray-400 text-center">
            <a :href="email.link" target="_blank" class="underline">{{
              email.emailAddress
            }}</a>
          </li>
        </ul>
      </section>
    </div>
    <div
      class="w-full sm:w-2/3 text-left"
      v-for="map in contactUs.map"
      :key="map.id"
    >
      <iframe
        :src="map.embedMap"
        width="100%"
        height="510"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
    </div>
  </div>
</template>
