<script>
import ConditionsWeTreatSingle from "../conditionswetreat/ConditionsWeTreatSingle.vue";
import conditionsWeTreatGrid from "../../content/conditionswetreat.json";

export default {
  components: { ConditionsWeTreatSingle },
  data: () => {
    return {
      conditionsWeTreatGrid,
      conditionsWeTreatGridHeading: "Conditions We Treat",
    };
  },
  computed: {
    filteredConditionsWeTreatGrid() {
      return this.conditionsWeTreatGrid;
    },
  },
};
</script>

<template>
  <section class="pt-10 sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-2 text-ternary-dark dark:text-ternary-light"
      >
        {{ conditionsWeTreatGridHeading }}
      </p>
    </div>

    <div>
      <ConditionsWeTreatSingle
        v-for="conditionswetreatgrid in filteredConditionsWeTreatGrid.slice(0, 3)"
        :key="conditionswetreatgrid.id"
        :conditionswetreatgrid="conditionswetreatgrid"
      />
    </div>
  </section>
</template>
