<script>
export default {
  name: "Home",
  data: () => ({
    images: [
      "images/banner/banner1.png",
      // "images/banner/injuryPrevention.jpg",
      // "images/banner/preAndPostSurgery.jpg",
      // "images/banner/iatsms.jpg",
      // "images/banner/geriatricPhysiotherapy.jpg",
    ],
    active: 0
  }),
  mounted() {
    let i = 0;
    setInterval(() => {
      if (i > this.images.length - 1) {
        i = 0;
      }
      this.active = i;
      i++;
    }, 5000);
  }
};
</script>

<template>
  <section
    class="flex flex-col sm:justify-between items-center sm:flex-row mt-10 sm:mt-10"
  >
    <div>
      <div class="relative slide">
        <div class="carousel-inner relative overflow-hidden w-full">
          <div
            v-for="(img, i) in images"
            :id="`slide-${i}`"
            :key="i"
            :class="`${active === i ? 'active' : 'left-full'}`"
            class="carousel-item inset-0 relative w-full transform transition-all duration-500 ease-in-out rounded-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
          >
            <img class="rounded-xl shadow-lg w-full border-none" :src="img" />
          </div>
        </div>
      </div>
    </div>
  </section>

</template>


<style>
.left-full {
  left: -100%;
}

.carousel-item {
  float: left;
  position: relative;
  display: block;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
}

.carousel-item.active {
  left: 0;
}
</style>
