<script>
import feather from "feather-icons";
import EducationalSeminarsSingle from "../educationalseminars/EducationalSeminarsSingle.vue";
import educationalSeminars from "../../content/educationalseminars.json";

export default {
  components: { EducationalSeminarsSingle },
  data: () => {
    return {
      educationalSeminars,
      educationalSeminarsHeading: "Educational Seminars",
    };
  },
  computed: {
    filteredEducationalSeminars() {
      return this.educationalSeminars;
    },
  },
  mounted() {
    feather.replace();
  },
};
</script>

<template>
  <section class="pt-10 sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-6 text-ternary-dark dark:text-ternary-light"
      >
        {{ educationalSeminarsHeading }}
      </p>
    </div>
    <div>
      <EducationalSeminarsSingle
        v-for="educationalSeminars in filteredEducationalSeminars"
        :key="educationalSeminars.id"
        :educationalSeminars="educationalSeminars"
      />
    </div>
  </section>
</template>
