<script>
export default {
  data: () => {
    return {
      projectName: "Parmar Physiotherapy.",
      author: "Prowerse.",
    };
  },
};
</script>

<template>
  <div class="flex justify-center items-center text-center">
    <div
      class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"
    >
      &copy;
      <a
        href="/"
        class="font-general-medium hover:underline hover:text-dark-blue-light dark:hover:text-indigo-300 duration-500"
      >
        {{ projectName }}
      </a>
      Developed by
      <a
        href="https://www.prowerse.com/"
        target="__blank"
        class="font-general-medium text-secondary-dark dark:text-secondary-light hover:underline hover:text-dark-blue-light dark:hover:text-indigo-300 duration-500"
        >{{ author }}</a
      >
    </div>
  </div>
</template>
