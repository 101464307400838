<script>
import ContactUsSingle from "../contactus/ContactUsSingle.vue";
import contactus from "../../content/contactus.json";

export default {
  components: { ContactUsSingle },
  data: () => {
    return {
      contactus,
      contactUsHeading: "Contact Us",
    };
  },
  computed: {
    filteredContactUs() {
      return this.contactus;
    },
  },
};
</script>

<template>
  <section class="pt-10 sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-6 text-ternary-dark dark:text-ternary-light"
      >
        {{ contactUsHeading }}
      </p>
    </div>
    <div>
      <ContactUsSingle
        v-for="contactUs in filteredContactUs"
        :key="contactUs.id"
        :contactUs="contactUs"
      />
    </div>
  </section>
</template>