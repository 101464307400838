<script>
export default {
  props: ["conditionswetreatgrid"],
};
</script>

<template>
  <div
    class="block sm:flex gap-0 sm:gap-10 mt-10 mb-10"
    v-if="conditionswetreatgrid.id % 2 !== 0"
  >
    <div class="w-full sm:w-2/3 mb-6 flex flex-col justify-center">
      <p
        class="text-3xl text-gray-800 font-general-semibold mb-3 dark:text-primary-light"
      >
        {{ conditionswetreatgrid.title }}
      </p>
      <p class="font-general-regular text-gray-400 text-justify">
        {{ conditionswetreatgrid.shortContent }}
      </p>
    </div>
    <div class="w-full sm:w-1/3 text-left">
      <div>
        <img
          :src="conditionswetreatgrid.img"
          :alt="conditionswetreatgrid.title"
          class="h-auto w-full rounded-xl shadow-lg hover:shadow-xl"
        />
      </div>
    </div>
  </div>
  <div
    class="block sm:flex gap-0 sm:gap-10 mt-10 mb-10"
    v-if="conditionswetreatgrid.id % 2 === 0"
  >
    <div class="w-full sm:w-1/3 text-left">
      <div>
        <img
          :src="conditionswetreatgrid.img"
          :alt="conditionswetreatgrid.title"
          class="hidden relative lg:block h-auto w-full rounded-xl shadow-lg hover:shadow-xl"
        />
      </div>
    </div>
    <div class="w-full sm:w-2/3 mb-6 flex flex-col justify-center">
      <p
        class="text-3xl text-gray-800 font-general-semibold mb-3 dark:text-primary-light"
      >
        {{ conditionswetreatgrid.title }}
      </p>
      <p class="font-general-regular text-gray-400 text-justify">
        {{ conditionswetreatgrid.shortContent }}
      </p>
    </div>
        <img
      :src="conditionswetreatgrid.img"
      :alt="conditionswetreatgrid.title"
      class="lg:hidden lg:block h-auto w-full rounded-xl shadow-lg hover:shadow-xl"
    />
  </div>
</template>
