<script>
export default {
  props: ["happypatients"],
};
</script>

<template>
      <div class="relative w-full h-full">
    <div class="absolute hidden w-full h-96"></div>
    <div class="relative mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl ">
      <div class="grid max-w-screen-md gap-10 md:grid-cols-2 sm:mx-auto">
        <div>
          <div class="rounded">
            <div class="text-center" v-for="firstImage in happypatients.firstRecord" :key="firstImage.id">
              <img :src="firstImage.img" />
            </div>
          </div>
        </div>
                <div>
          <div class="rounded">
            <div class="mb-4 text-center" v-for="secondImage in happypatients.secondRecord" :key="secondImage.id">
              <img :src="secondImage.img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div>
      <img
        :src="happypatients.img"
        class="rounded-xl border-none mb-10"
      />
    </div> -->
  <!-- <div class="mt-10 sm:mt-10">
    <div class="flex flex-wrap justify-center mt-10" v-for="firstImage in happypatients.firstRecord" :key="firstImage.id">
      <div class="sm:w-4/12">
        <img :src="firstImage.img" alt="..." class="shadow-lg rounded w-full h-auto align-middle border-none" />
      </div>
    </div>
    <div
      class="grid gap-6 row-gap-5 mb-6 lg:grid-cols-2 sm:row-gap-6 sm:grid-cols-2"
    >
      <div v-for="firstImage in happypatients.firstRecord" :key="firstImage.id">
        <div
          class="relative overflow-hidden transition duration-200 transform rounded shadow-lg"
        >
          <img
            class="object-cover w-full h-80 md:h-64 xl:h-80 align-middle"
            :src="firstImage.img"
          />
        </div>
      </div>
      <div
        v-for="secondImage in happypatients.secondRecord"
        :key="secondImage.id"
      >
        <div
          class="relative overflow-hidden transition duration-200 transform rounded shadow-lg"
        >
          <img
            class="object-cover w-full h-80 md:h-64 xl:h-80"
            :src="secondImage.img"
          />
        </div>
      </div>
      <div v-for="thirdImage in happypatients.thirdRecord" :key="thirdImage.id">
        <div
          class="relative overflow-hidden transition duration-200 transform rounded shadow-lg"
        >
          <img
            class="object-cover w-full h-80 md:h-64 xl:h-80"
            :src="thirdImage.img"
          />
        </div>
      </div>
      <div
        v-for="fourthImage in happypatients.fourthRecord"
        :key="fourthImage.id"
      >
        <div
          class="relative overflow-hidden transition duration-200 transform rounded shadow-lg"
        >
          <img
            class="object-cover w-full h-80 md:h-64 xl:h-80"
            :src="fourthImage.img"
            alt=""
          />
        </div>
      </div>
    </div>
  </div> -->
</template>
