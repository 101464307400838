<script>
export default {
  props: ["educationalSeminars"],
};
</script>

<template>
  <div class="mt-10 sm:mt-4">
    <div class="relative mb-6 sm:mx-auto md:max-w-md lg:max-w-lg">
      <img class="rounded-xl border-none w-full" :src="educationalSeminars.img" />
    </div>
    <div class="mb-16 md:mb-0 md:max-w-xl sm:mx-auto md:text-center">
      <h2 class="font-general-regular text-xl text-gray-400 mb-2">
        {{ educationalSeminars.title }}
      </h2>
    </div>
  </div>
</template>
