<script>
export default {
  props: ["meetthedoctor"],
};
</script>

<template>
  <div class="mt-10 sm:mt-10">
    <div class="grid gap-5 row-gap-10 lg:grid-cols-2">
      <div>
        <img
          class="object-fit w-full rounded shadow-lg sm:h-full rounded-xl shadow-lg hover:shadow-xl"
          :src="meetthedoctor.img"
        />
      </div>
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2
            class="mb-6 text-3xl font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold uppercase text-justify"
          >
            {{ meetthedoctor.doctorName }}
          </h2>
          <p
            class="text-2xl font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold text-left"
          >
            {{ meetthedoctor.degree1 }}
          </p>
          <!-- <p
            class="text-2xl font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold text-justify"
          >
            {{ meetthedoctor.degree2 }}
          </p> -->
        </div>
        <div
          v-for="certificate in meetthedoctor.certificate"
          :key="certificate.id"
        >
          <p
            class="mb-2 text-2xl font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold text-justify"
          >
            {{ certificate.title }}
          </p>
          <div class="">
            <ul class="mb-2 font-general-regular text-gray-400">
              <li class="flex text-xl">
                {{ certificate.certificate1 }}
              </li>
              <li class="flex text-xl">
                {{ certificate.certificate2 }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
