<script>

import TestimonialsSingle from "../testimonials/TestimonialsSingle.vue";
import testimonials from "../../content/testimonials.json";

export default {
  components: { TestimonialsSingle },
  data: () => {
    return {
      testimonials,
      testimonialsHeading: "Testimonials",
    };
  },
  computed: {
    filteredTestimonials() {
      return this.testimonials;
    },
  },
};
</script>

<template>
  <section class="sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-6 text-ternary-dark dark:text-ternary-light"
      >
        {{ testimonialsHeading }}
      </p>
    </div>
    <div>
      <TestimonialsSingle
        v-for="testimonials in filteredTestimonials"
        :key="testimonials.id"
        :testimonials="testimonials"
      />
    </div>
  </section>
</template>
