<script>
import HappyPatientsSingle from "../happypatients/HappyPatientsSingle.vue";
import happypatients from "../../content/happypatients.json";

export default {
  components: { HappyPatientsSingle },
  data: () => {
    return {
      happypatients,
      happyPatientsHeading: "Happy Patients",
    };
  },
  computed: {
    filteredHappyPatients() {
      return this.happypatients;
    },
  },
};
</script>

<template>
  <section class="pt-10 sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-6 text-ternary-dark dark:text-ternary-light"
      >
        {{ happyPatientsHeading }}
      </p>
    </div>
    <div>
      <HappyPatientsSingle
        v-for="happypatients in filteredHappyPatients"
        :key="happypatients.id"
        :happypatients="happypatients"
      />
    </div>
  </section>
</template>
