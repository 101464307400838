<script>
import feather from 'feather-icons';
import LaserSuccessStoriesSingle from '../lasersuccessstories/LaserSuccessStoriesSingle.vue';
import laserSuccessStories from '../../content/lasersuccessstories.json';

export default {
  components: { LaserSuccessStoriesSingle },
  data: () => {
    return {
      laserSuccessStories,
      laserSuccessStoriesHeading: "LASER Success Stories",
    };
  },
  computed: {
		filteredLaserSuccessStories() {
			return this.laserSuccessStories;
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
  <section class="sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-6 text-ternary-dark dark:text-ternary-light"
      >
        {{ laserSuccessStoriesHeading }}
      </p>
    </div>
    <div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10"
		>
			<LaserSuccessStoriesSingle
				v-for="laserSuccessStories in filteredLaserSuccessStories"
				:key="laserSuccessStories.id"
				:laserSuccessStories="laserSuccessStories"
			/>
		</div>
  </section>
</template>
