<script>
export default {
  props: ["testimonials"],
};
</script>

<template>
  <div class="mt-10 sm:mt-6">
    <div class="mb-10 md:mb-6">
      <p
        class="font-general-regular text-gray-400 text-justify sm:text-center md:text-lg"
      >
        {{ testimonials.customerReview }}
      </p>
    </div>
    <!-- <div class="carousel relative shadow-2xl bg-white">
      <div class="carousel-inner relative overflow-hidden w-full">
        <input
          class="carousel-open"
          type="radio"
          id="carousel-1"
          name="carousel"
          aria-hidden="true"
          hidden="true"
          checked="checked"
        />
        <div
          class="carousel-item absolute opacity-0 cursor-pointer"
          style="height:80vh;"
          v-for="firstVideo in testimonials.firstVideo"
          :key="firstVideo.id"
        >
          <iframe
            class="block h-full w-full bg-indigo-500 text-white text-5xl text-center"
            :src="firstVideo.link"
          ></iframe>
        </div>
        <label
          for="carousel-5"
          class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-2"
          class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >
        <input
          class="carousel-open"
          type="radio"
          id="carousel-2"
          name="carousel"
          aria-hidden="true"
          hidden="true"
        />
        <div
          class="carousel-item absolute opacity-0 cursor-pointer"
          style="height:80vh;"
          v-for="secondVideo in testimonials.secondVideo"
          :key="secondVideo.id"
        >
          <iframe
            class="block h-full w-full bg-indigo-500 text-white text-5xl text-center"
            :src="secondVideo.link"
          ></iframe>
        </div>
        <label
          for="carousel-1"
          class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-3"
          class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >
        <input
          class="carousel-open"
          type="radio"
          id="carousel-3"
          name="carousel"
          aria-hidden="true"
          hidden="true"
        />
        <div
          class="carousel-item absolute opacity-0 cursor-pointer"
          style="height:80vh;"
          v-for="thirdVideo in testimonials.thirdVideo"
          :key="thirdVideo.id"
        >
          <iframe
            class="block h-full w-full bg-indigo-500 text-white text-5xl text-center"
            :src="thirdVideo.link"
          ></iframe>
        </div>
        <label
          for="carousel-2"
          class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-4"
          class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >
        <input
          class="carousel-open"
          type="radio"
          id="carousel-4"
          name="carousel"
          aria-hidden="true"
          hidden="true"
        />
        <div
          class="carousel-item absolute opacity-0 cursor-pointer"
          style="height:80vh;"
          v-for="fourthVideo in testimonials.fourthVideo"
          :key="fourthVideo.id"
        >
          <iframe
            class="block h-full w-full bg-indigo-500 text-white text-5xl text-center"
            :src="fourthVideo.link"
          ></iframe>
        </div>
        <label
          for="carousel-3"
          class="prev control-4 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-5"
          class="next control-4 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >
        <input
          class="carousel-open"
          type="radio"
          id="carousel-5"
          name="carousel"
          aria-hidden="true"
          hidden="true"
        />
        <div
          class="carousel-item absolute opacity-0 cursor-pointer"
          style="height:80vh;"
          v-for="fifthVideo in testimonials.fifthVideo"
          :key="fifthVideo.id"
        >
          <iframe
            class="block h-full w-full bg-indigo-500 text-white text-5xl text-center"
            :src="fifthVideo.link"
          ></iframe>
        </div>
        <label
          for="carousel-4"
          class="prev control-5 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-1"
          class="next control-5 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >
      </div>
    </div> -->
    <div
      class="grid gap-5 row-gap-10 lg:grid-cols-2 mb-6"
      v-for="firstVideo in testimonials.firstVideo"
      :key="firstVideo.id"
    >
      <div>
        <iframe
          class="object-fit w-full h-64 lg:h-92 rounded shadow-lg sm:h-full rounded-xl shadow-lg hover:shadow-xl"
          :src="firstVideo.link"
        ></iframe>
      </div>
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2
            class="flex flex-col justify-center font-general-regular text-gray-400 text-left md:text-lg"
          >
            {{ firstVideo.customerText }}
          </h2>
        </div>
      </div>
    </div>
    <div
      class="grid gap-5 row-gap-10 lg:grid-cols-2 mb-6"
      v-for="secondVideo in testimonials.secondVideo"
      :key="secondVideo.id"
    >
      <div>
        <iframe
          class="object-fit w-full h-64 lg:h-92 rounded shadow-lg sm:h-full rounded-xl shadow-lg hover:shadow-xl"
          :src="secondVideo.link"
        ></iframe>
      </div>
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2
            class="flex flex-col justify-center font-general-regular text-gray-400 text-left md:text-lg"
          >
            {{ secondVideo.customerText }}
          </h2>
        </div>
      </div>
    </div>
    <div
      class="grid gap-5 row-gap-10 lg:grid-cols-2 mb-6"
      v-for="thirdVideo in testimonials.thirdVideo"
      :key="thirdVideo.id"
    >
      <div>
        <iframe
          class="object-fit w-full h-64 lg:h-92 rounded shadow-lg sm:h-full rounded-xl shadow-lg hover:shadow-xl"
          :src="thirdVideo.link"
        ></iframe>
      </div>
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2
            class="flex flex-col justify-center font-general-regular text-gray-400 text-left md:text-lg"
          >
            {{ thirdVideo.customerText }}
          </h2>
        </div>
      </div>
    </div>
    <div
      class="grid gap-5 row-gap-10 lg:grid-cols-2 mb-6"
      v-for="fourthVideo in testimonials.fourthVideo"
      :key="fourthVideo.id"
    >
      <div>
        <iframe
          class="object-fit w-full h-64 lg:h-92 rounded shadow-lg sm:h-full rounded-xl shadow-lg hover:shadow-xl"
          :src="fourthVideo.link"
        ></iframe>
      </div>
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2
            class="flex flex-col justify-center font-general-regular text-gray-400 text-left md:text-lg"
          >
            {{ fourthVideo.customerText }}
          </h2>
        </div>
      </div>
    </div>
    <div
      class="grid gap-5 row-gap-10 lg:grid-cols-2 mb-6"
      v-for="fifthVideo in testimonials.fifthVideo"
      :key="fifthVideo.id"
    >
      <div>
        <iframe
          class="object-fit w-full h-64 lg:h-92 rounded shadow-lg sm:h-full rounded-xl shadow-lg hover:shadow-xl"
          :src="fifthVideo.link"
        ></iframe>
      </div>
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2
            class="flex flex-col justify-center font-general-regular text-gray-400 text-left md:text-lg"
          >
            {{ fifthVideo.customerText }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <style>
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3,
#carousel-4:checked ~ .control-4,
#carousel-5:checked ~ .control-5 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
</style> -->
