<script>
import ThemeSwitcher from "../ThemeSwitcher";
import feather from "feather-icons";
export default {
  components: {
    ThemeSwitcher,
  },
  data() {
    return {
      isOpen: false,
      theme: "",
      modal: false
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
  },
  methods: {
    updateTheme(theme) {
      this.theme = theme;
    },
    closeMenu() {
      var element = document.getElementById("demo");
      element.classList.replace("block", "hidden");
      this.isOpen = false;
    },
    updated() {
      feather.replace();
    },
  }
};
</script>

<template>
  <nav id="nav" class="sm:container sm:mx-auto">
    <!-- Header start -->
    <div
      class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center my-6"
    >
      <!-- Header menu links and small screen hamburger menu -->
      <div class="flex justify-between items-center px-4 sm:px-0">
        <!-- Header logos -->
        <div>
          <router-link to="/" @click="closeMenu()" class="flex items-center">
            <img src="images/header.png" class="mr-3 h-16 sm:h-16" />
            <h1
              class="font-general-semibold text-xl md:text-3xl xl:text-4xl text-left sm:text-left text-dark-blue-light dark:text-primary-light uppercase"
            >
              Parmar Physiotherapy
            </h1>
          </router-link>
        </div>

        <!-- Theme switcher small screen -->
        <theme-switcher
          :theme="theme"
          @themeChanged="updateTheme"
          class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg"
        />

        <!-- Small screen hamburger menu -->
        <div class="sm:hidden">
          <button
            @click="isOpen = !isOpen"
            type="button"
            class="focus:outline-none"
            aria-label="Hamburger Menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light"
            >
              <path
                v-if="isOpen"
                fill-rule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                clip-rule="evenodd"
              ></path>
              <path
                v-if="!isOpen"
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Header links -->
  <div
    :class="isOpen ? 'block': 'hidden'"
    id="demo"
    class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
  >
    <router-link
      to="/aboutus"
      @click="closeMenu()"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-dark-blue-light dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 "
      >About</router-link
    >
    <router-link
      to="/services"
      @click="closeMenu()"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-dark-blue-light dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 "
      >Services</router-link
    >
    <router-link
      to="/conditionswetreat"
      @click="closeMenu()"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-dark-blue-light dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 "
      >Conditions We Treat</router-link
    >
    <router-link
      to="/contactus"
      @click="closeMenu()"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-dark-blue-light dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 "
      >Contact Us</router-link
    >
  </div>

      <!-- Header right section buttons -->
      <div
        class="hidden sm:flex justify-between items-center flex-col md:flex-row"
      >
        <!-- Theme switcher large screen -->
        <theme-switcher
          :theme="theme"
          @themeChanged="updateTheme"
          class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer"
        />
      </div>
    </div>
  </nav>
</template>
