<script>
import MeetTheDoctorSingle from "../meetthedoctor/MeetTheDoctorSingle.vue";
import meetthedoctor from "../../content/meetthedoctor.json";

export default {
  components: { MeetTheDoctorSingle },
  data: () => {
    return {
      meetthedoctor,
      meetTheDoctorHeading: "Meet The Doctor",
    };
  },
  computed: {
    filteredMeetTheDoctor() {
      return this.meetthedoctor;
    },
  },
};
</script>

<template>
  <section class="sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-6 text-ternary-dark dark:text-ternary-light"
      >
        {{ meetTheDoctorHeading }}
      </p>
    </div>
    <div>
      <MeetTheDoctorSingle
        v-for="meetthedoctor in filteredMeetTheDoctor"
        :key="meetthedoctor.id"
        :meetthedoctor="meetthedoctor"
      />
    </div>
  </section>
</template>
