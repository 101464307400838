<script>
export default {
  props: ["laserSuccessStories"],
};
</script>

<template>
  <div
    class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
  >
    <div>
      <img
        :src="laserSuccessStories.img"
        :alt="laserSuccessStories.title"
        class="rounded-t-xl h-80 w-full lg:px-20 border-none"
      />
    </div>
    <div class="text-center px-4 py-6">
      <p
        class="font-general-regular text-gray-400 text-justify"
      >
        {{ laserSuccessStories.title }}
      </p>
    </div>
  </div>
</template>
